import React, { useState, useRef, useEffect } from "react";
import { Star, ChevronDown, ChevronUp } from "lucide-react";
import { motion, AnimatePresence } from "framer-motion";
import { useNavigate, Link, useLocation } from "react-router-dom";
import ExampleResultCard from "./ExampleResultCard";
import Footer from "./Footer";
import HeroLogo from "../assets/HeroLogo";
import ProgressBarIllu from "../assets/ProgressBarIllu";
import { scrollToSection, getHashFromUrl } from "../utils/scrollUtils";
import { useInView } from "react-intersection-observer";
import { useAuth } from "../auth/AuthContext";
import EmailSignInForm from './EmailSignInForm';

// Animation variants
const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.2,
      delayChildren: 0.1,
    },
  },
};

const itemVariants = {
  hidden: {
    opacity: 0,
    y: 20,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.5,
      ease: "easeOut",
    },
  },
};

// Reusable animated section component
const AnimatedSection = ({ children, className }) => {
  const [ref, inView] = useInView({
    threshold: 0.2,
    triggerOnce: true,
  });

  return (
    <motion.section
      ref={ref}
      initial="hidden"
      animate={inView ? "visible" : "hidden"}
      variants={containerVariants}
      className={className}
    >
      {children}
    </motion.section>
  );
};

// Reusable animated element component
const AnimatedElement = ({ children, className, delay = 0 }) => {
  return (
    <motion.div
      variants={{
        ...itemVariants,
        visible: {
          ...itemVariants.visible,
          transition: {
            ...itemVariants.visible.transition,
            delay,
          },
        },
      }}
      className={className}
    >
      {children}
    </motion.div>
  );
};

// Testimonials data
const testimonials = [
  {
    quote:
      "Ich habe einen fröhlichen Popsong für die Taufe meiner Tochter erstellt. Die Familie war begeistert, und jetzt haben wir eine besondere Erinnerung an diesen Tag.",
    author: "Julia N., 34",
    location: "Hannover",
    image:
      "https://hebbkx1anhila5yf.public.blob.vercel-storage.com/Author-1-r8lACzDlZtjecqPRVVKSCiYegV3STF.png",
  },
  {
    quote:
      "Meine beste Freundin liebt Rap und ich wollte sie zum Lachen bringen. Ich hab' ein paar Insider-Witze in den Text eingebaut und sie hat sich kaputtgelacht.",
    author: "Lea D., 27",
    location: "Darmstadt",
    image:
      "https://hebbkx1anhila5yf.public.blob.vercel-storage.com/Author-2-du4a4y6mEUUEc66f3SWsLMdC0PixG4.png",
  },
  {
    quote:
      "Ich hab' meiner Schwester einen chilligen RnB-Song zur Schwangerschaft geschickt, genau ihr Ding. Sie meinte, das wird ihr Lieblingslied für jede Autofahrt mit dem Baby.",
    author: "Rico K., 19",
    location: "Cuxhaven",
    image:
      "https://hebbkx1anhila5yf.public.blob.vercel-storage.com/Author-3-FZHaWJaJorwrUx8jRTFP6N2MceZztx.png",
  },
];

const variants = {
  enter: (direction) => {
    return {
      x: direction > 0 ? 300 : -300,
      opacity: 0,
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: (direction) => {
    return {
      zIndex: 0,
      x: direction < 0 ? 300 : -300,
      opacity: 0,
    };
  },
};

const occasions = [
  {
    name: "Geburtstag",
    color: "#FF4D4D",
    textColor: "#FFF3DD",
    rotation: -5,
    path: "/wedding",
  },
  {
    name: "Urlaubsgruß",
    color: "#EBD2FE",
    textColor: "#42052B",
    rotation: 3,
    path: "/wedding",
  },
  {
    name: "Schwangerschaft",
    color: "#FFCD4D",
    textColor: "#42052B",
    rotation: -2,
    path: "/wedding",
  },
  {
    name: "Hochzeit",
    color: "#FF4D4D",
    textColor: "#FFF3DD",
    rotation: 4,
    path: "/wedding",
  },
  {
    name: "Feiertag",
    color: "#FFCD4D",
    textColor: "#42052B",
    rotation: -3,
    path: "/wedding",
  },
  {
    name: "Taufe",
    color: "#EBD2FE",
    textColor: "#42052B",
    rotation: 2,
    path: "/wedding",
  },
  {
    name: "Jahrestag",
    color: "#FF4D4D",
    textColor: "#FFF3DD",
    rotation: -4,
    path: "/wedding",
  },
  {
    name: "Umzug",
    color: "#EBD2FE",
    textColor: "#42052B",
    rotation: 3,
    path: "/wedding",
  },
  {
    name: "Dankeschön",
    color: "#FFCD4D",
    textColor: "#42052B",
    rotation: -2,
    path: "/wedding",
  },
  {
    name: "Abschluss",
    color: "#FF4D4D",
    textColor: "#FFF3DD",
    rotation: 4,
    path: "/wedding",
  },
  {
    name: "Glückwünsche",
    color: "#FF4D4D",
    textColor: "#FFF3DD",
    rotation: -3,
    path: "/wedding",
  },
  {
    name: "Promotion",
    color: "#FFCD4D",
    textColor: "#42052B",
    rotation: 2,
    path: "/wedding",
  },
  {
    name: "Jubiläum",
    color: "#FF4D4D",
    textColor: "#FFF3DD",
    rotation: -4,
    path: "/wedding",
  },
  {
    name: "Gute Besserung",
    color: "#EBD2FE",
    textColor: "#42052B",
    rotation: 3,
    path: "/wedding",
  },
];

const faqItems = [
  {
    question: "Für welchen Anlass ist ein Tralala-Song das geeignete Geschenk?",
    answer:
      "Es gibt immer einen Grund zu Feiern. Einige Beispiele, bei denen ein personalisierter Song ein tolles Geschenk sein kann: Geburtstag, Schwangerschaft, Hochzeit, Urlaubsgruß, Glückwünsche, Jahrestag, Dankeschön, Gute Besserung, Abschluss, Taufe, Jubiläum, Promotion, Umzug, …",
  },
  {
    question: "Wie funktioniert Tralala?",
    answer:
      "Ganz einfach! Du beantwortest ein paar simple Fragen: Für wen ist der Song? Was feiern wir? Möchtest du etwas besonderes sagen? Welcher Musikstil soll der Song werden? Mit diesen Infos erstellen wir den Song und du kannst ihn nach nur wenigen Minuten anhören und herunterladen.",
  },
  {
    question: "Was kostet Tralala?",
    answer:
      "Unser Preis von 2,90€ pro Song spiegelt die Einzigartigkeit und den persönlichen Wert jedes individuell erstellten Liedes wider. Dieser faire Preis ermöglicht es uns, hochwertige, maßgeschneiderte Songs zu produzieren und gleichzeitig unseren Service für jeden erschwinglich zu halten. Mit Tralala erhältst du ein einzigartiges, persönliches Geschenk, das weit über den materiellen Wert hinausgeht und unvergessliche Erinnerungen schafft.",
  },
  {
    question: "Was mache ich, wenn mir der erstellte Song nicht gefällt?",
    answer:
      "Wir arbeiten derzeit an einem Prozess, um solche Situationen bestmöglich zu handhaben. Unser Ziel ist es, dass jeder Kunde mit seinem personalisierten Song zufrieden ist. Wenn du Bedenken hast, kontaktiere bitte unseren Kundenservice. Wir werden unser Bestes tun, um eine Lösung zu finden, die dich glücklich macht.",
  },
];

const Typewriter = ({ words, period = 2000 }) => {
  const [index, setIndex] = useState(0);
  const [subIndex, setSubIndex] = useState(0);
  const [reverse, setReverse] = useState(false);
  const [blink, setBlink] = useState(true);
  const [startDelay, setStartDelay] = useState(true);

  useEffect(() => {
    if (startDelay) {
      const timeout = setTimeout(() => {
        setStartDelay(false);
      }, 2000); // 2 second delay before starting
      return () => clearTimeout(timeout);
    }

    if (subIndex === words[index].length + 1 && !reverse) {
      setReverse(true);
      return;
    }

    if (subIndex === 0 && reverse) {
      setReverse(false);
      setIndex((prev) => (prev + 1) % words.length);
      return;
    }

    const timeout = setTimeout(() => {
      setSubIndex((prev) => prev + (reverse ? -1 : 1));
    }, Math.max(reverse ? 50 : subIndex === words[index].length ? 1000 : 100, parseInt(Math.random() * 100)));

    return () => clearTimeout(timeout);
  }, [subIndex, index, reverse, words, startDelay]);

  useEffect(() => {
    const timeout2 = setTimeout(() => {
      setBlink((prev) => !prev);
    }, 500);
    return () => clearTimeout(timeout2);
  }, [blink]);

  return (
    <span className="whitespace-nowrap inline-block transition-all duration-100 ease-in-out">
      {startDelay
        ? "jeden Anlass"
        : `${words[index].substring(0, subIndex)}${blink ? "|" : " "}`}
    </span>
  );
};

const LandingPage = () => {
  const { user, authError } = useAuth();
  const [email, setEmail] = useState("");
  const [status, setStatus] = useState("idle");
  const navigate = useNavigate();
  const [[currentTestimonial, direction], setPage] = useState([0, 0]);
  const [showFloatingForm, setShowFloatingForm] = useState(false);
  const [faqStates, setFaqStates] = useState(
    Array(faqItems.length).fill(false)
  );
  const heroRef = useRef(null);
  const staticFormRef = useRef(null);

  const swipeConfidenceThreshold = 10000;
  const swipePower = (offset, velocity) => {
    return Math.abs(offset) * velocity;
  };

  const paginate = (newDirection) => {
    setPage((prevPage) => [
      (prevPage[0] + newDirection + testimonials.length) % testimonials.length,
      newDirection,
    ]);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (staticFormRef.current) {
        const staticFormRect = staticFormRef.current.getBoundingClientRect();
        const viewportHeight = window.innerHeight;
        const threshold = viewportHeight * 0.8; // 20% threshold

        // Show floating form when static form is above threshold AND not visible in viewport
        setShowFloatingForm(
          staticFormRect.bottom < threshold && // Past threshold
            staticFormRect.bottom < 0 // Completely out of view
        );
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      paginate(1);
    }, 6000);

    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    if (user) {
      navigate("/generator");
    }
  }, [user, navigate]);

  const toggleFAQ = (index) => {
    setFaqStates((prev) => {
      const newStates = [...prev];
      newStates[index] = !newStates[index]; // Toggle the specific FAQ
      return newStates;
    });
  };

  const FAQ = ({ question, answer, isOpen, toggleFAQ }) => {
    return (
      <div className="border-b border-tralala-purple/20 py-4">
        <button
          className="flex justify-between items-center w-full text-left text-tralala-purple hover:text-tralala-purple/80 transition-colors duration-200"
          onClick={toggleFAQ}
        >
          <span className="text-lg md:text-xl font-semibold max-w-[70ch] break-words">
            {question}
          </span>
          <span className={`transform transition-transform duration-200`}>
            {isOpen ? <ChevronUp /> : <ChevronDown />}
          </span>
        </button>
        {isOpen && (
          <div className="mt-2 text-tralala-purple/80 text-base md:text-lg">
            {answer}
          </div>
        )}
      </div>
    );
  };

  const location = useLocation();

  useEffect(() => {
    const sectionId = getHashFromUrl(location.hash);
    if (sectionId) {
      scrollToSection(sectionId);
    }
  }, [location]);

  const renderEmailForm = (isFloating) => (
    <EmailSignInForm
      isFloating={isFloating}
      email={email}
      setEmail={setEmail}
      status={status}
      setStatus={setStatus}
      authError={authError}
    />
  );

  return (
    <div className="bg-tralala-bg min-h-screen">
      {/* New Announcement Bar */}
      <div className="sticky top-0 z-50 bg-[#EBD2FE] text-[#4B002F] h-8 flex items-center justify-center">
        <p className="text-xs md:text-sm font-semibold text-center px-4">
        Angebot zum Start von Tralala – Jetzt nur 2,90€ statt <s>4.90€</s>
        </p>
      </div>

      {/* Fixed Logo - Adjusted position to account for announcement bar */}
      <div className="fixed top-12 left-0 right-0 z-50 flex justify-center">
        <HeroLogo className="w-48 md:w-64" alt="TRALALA" />
      </div>

      {/* Hero Section */}
      <section
        ref={heroRef}
        id="hero"
        className="px-4 py-8 md:py-16 bg-cover bg-center relative min-h-screen flex flex-col"
        style={{
          backgroundImage: "url('/Tralala-Hero-Image.jpg')",
        }}
      >
        {/* Background divs - Not Animated */}
        <div
          className="absolute inset-0 bg-[#FFF3DD] pointer-events-none"
          style={{ height: "50vh", top: 0 }}
        ></div>
        <div
          className="absolute inset-0 bg-gradient-to-b from-[#FFF3DD] to-transparent pointer-events-none"
          style={{ top: "50vh" }}
        ></div>

        <motion.div
          initial="hidden"
          animate="visible"
          variants={containerVariants}
          className="max-w-7xl mx-auto text-center relative z-10 flex flex-col flex-grow"
        >
          {/* Hero content */}
          <div className="flex-grow space-y-12 md:space-y-16 lg:space-y-20 pt-24 md:pt-32">
            <motion.div variants={itemVariants}>
              <h2 className="text-[#42052B] text-5xl md:text-7xl font-medium mb-6 leading-[90%] tracking-[-0.02em]">
                Ein <span className="text-[#FF4D4D]">Song als</span>
                <br />
                <span className="text-[#FF4D4D]">Geschenk</span> für
                <br />
                <Typewriter
                  words={[
                    "Geburtstage",
                    "Hochzeiten",
                    "Feiertage",
                    "Abschlüsse",
                    "jeden Anlass",
                  ]}
                />
              </h2>
              <motion.p
                variants={itemVariants}
                className="text-tralala-purple text-center text-lg lg:text-[1.5rem] mb-8 leading-[110%]"
              >
                Überrasche deine Lieblingsmenschen
                <br />
                <span className="whitespace-nowrap">
                  mit einem individuellem Song.
                </span>
              </motion.p>
            </motion.div>
          </div>

          {/* Form Container - Updated bottom margin */}
          <div className="w-full mb-20 sm:mb-8" ref={staticFormRef}>
            <motion.div
              variants={itemVariants}
              className="space-y-4 mx-auto w-full max-w-lg"
            >
              {renderEmailForm(false)}
            </motion.div>
          </div>
        </motion.div>
      </section>

      {/* Examples Section */}
      <div id="beispiele">
        <AnimatedSection className="px-4 py-20 md:py-16 lg:py-40">
          <div className="max-w-[calc(100vw-3rem)] md:max-w-[calc(100vw-16rem)] mx-auto">
            <AnimatedElement className="flex justify-center mb-4">
              <ProgressBarIllu className="mb-4" alt="Progress Bar" />
            </AnimatedElement>
            <AnimatedElement>
              <h2 className="text-tralala-purple text-3xl md:text-[3rem] font-medium mb-8 text-center leading-[105%] tracking-[-0.01em]">
                So könnte dein
                <br />
                <span className="whitespace-nowrap">Song klingen</span>
              </h2>
            </AnimatedElement>
            <AnimatedElement className="grid gap-6 grid-cols-1 md:grid-cols-3">
              <ExampleResultCard
                title="Tony's 30. Geburtstag"
                storagePath="example_songs/Tonys_30_Geburtstag.mp3"
                backgroundColor="#FF4D4D"
                textColor="#FFF3DD"
                tags={["Pop", "Birthday", "Celebration"]}
              />
              <ExampleResultCard
                title="Monika und Frank's Hochzeit"
                storagePath="example_songs/Monikas_Hochzeitslied.mp3"
                backgroundColor="#FFCD4D"
                textColor="#42052B"
                tags={["Ballad", "Love"]}
              />
              <ExampleResultCard
                title="Noah hat Abitur"
                storagePath="example_songs/Feiere_Noah.mp3"
                backgroundColor="#EBD2FE"
                textColor="#42052B"
                tags={["Folk", "Auslandsjahr", "Celebration"]}
              />
            </AnimatedElement>
          </div>
        </AnimatedSection>
      </div>

      {/* Testimonials Section */}
      <AnimatedSection className="px-4 py-8 md:py-16 lg:py-32 text-center overflow-hidden">
        <div className="max-w-3xl mx-auto">
          <AnimatedElement>
            <h2 className="text-[#FF4D4D] text-xl md:text-[1.5rem] font-medium mb-6 md:mb-8">
              <span className="whitespace-nowrap">"Tralala-was?"</span>
            </h2>
          </AnimatedElement>
          <div className="relative">
            <AnimatePresence initial={false} custom={direction} mode="wait">
              <motion.div
                key={currentTestimonial}
                custom={direction}
                variants={variants}
                initial="enter"
                animate="center"
                exit="exit"
                transition={{
                  x: {
                    type: "spring",
                    stiffness: 300,
                    damping: 30,
                    duration: 0.5,
                  },
                  opacity: { duration: 0.3 },
                }}
                className="testimonial-container h-[250px] md:relative overflow-hidden"
                drag="x"
                dragConstraints={{ left: 0, right: 0 }}
                dragElastic={1}
                onDragEnd={(e, { offset, velocity }) => {
                  const swipe = swipePower(offset.x, velocity.x);
                  if (swipe < -swipeConfidenceThreshold) {
                    paginate(1);
                  } else if (swipe > swipeConfidenceThreshold) {
                    paginate(-1);
                  }
                }}
              >
                <div className="absolute inset-0">
                  <AnimatedElement>
                    <p
                      className="text-tralala-purple text-lg md:text-3xl mb-8 max-w-2xl mx-auto leading-[125%] tracking-[-0.01em]"
                      style={{ maxWidth: "45ch" }}
                    >
                      "{testimonials[currentTestimonial].quote}"
                    </p>
                  </AnimatedElement>
                  <AnimatedElement className="flex items-center justify-center space-x-4 md:space-x-8 mb-4">
                    <img
                      src={testimonials[currentTestimonial].image}
                      alt={testimonials[currentTestimonial].author}
                      className="w-12 h-12 md:w-14 md:h-14 rounded-full"
                    />
                    <div className="text-tralala-purple text-left">
                      <p className="font-semibold text-base md:text-[1.5rem] lg:text-xl">
                        {testimonials[currentTestimonial].author}
                      </p>
                      <p className="text-sm md:text-[1.5rem] lg:text-xl">
                        {testimonials[currentTestimonial].location}
                      </p>
                    </div>
                    <div className="flex space-x-1">
                      {[...Array(5)].map((_, i) => (
                        <Star
                          key={i}
                          className="w-6 h-6 md:w-8 md:h-8 text-tralala-yellow fill-current"
                        />
                      ))}
                    </div>
                  </AnimatedElement>
                </div>
              </motion.div>
            </AnimatePresence>
            <div className="flex justify-center space-x-2 mt-2 md:mt-2">
              {testimonials.map((_, index) => (
                <button
                  key={index}
                  onClick={() => paginate(index - currentTestimonial)}
                  className={`w-2 h-2 rounded-full border ${
                    index === currentTestimonial
                      ? "bg-[#4B002F] border-[#4B002F]"
                      : "bg-[#FFF3DD] border-[#4B002F]"
                  }`}
                  aria-label={`Go to testimonial ${index + 1}`}
                />
              ))}
            </div>
          </div>
        </div>
      </AnimatedSection>

      {/* How it Works Section */}
      <div id="so-funktionierts">
        <AnimatedSection className="px-4 py-20 md:py-32 text-center">
          <div className="max-w-[calc(100vw-3rem)] md:max-w-[calc(100vw-16rem)] mx-auto">
            <AnimatedElement>
              <h2 className="text-[2rem] md:text-[3rem] font-medium mb-6 md:mb-16 leading-[2.1rem] md:leading-[3.15rem] tracking-[-0.01em]">
                <span className="text-tralala-red whitespace-nowrap">
                  So einfach ist Tralala
                </span>
                <br />
                <span className="text-tralala-purple">
                  In ein paar Fragen zu{" "}
                  <span className="whitespace-nowrap">deinem Song!</span>
                </span>
              </h2>
            </AnimatedElement>
            <div className="grid grid-cols-2 md:grid-cols-4 gap-4 md:gap-8">
              {[
                {
                  title: "1 – Für wen ist der Song?",
                  description: "Bestie? Deine Mama? Onkel Simon?",
                  image: "/How-it-works-1.jpg",
                },
                {
                  title: "2 – Was wird gefeiert?",
                  description: "Geburtstag, Hochzeit oder einfach so?",
                  image: "/How-it-works-2.jpg",
                },
                {
                  title: "3 – Was möchtest du sagen?",
                  description: "Lieblingsprüche, Insider oder ein Witz?",
                  image: "/How-it-works-3.jpg",
                },
                {
                  title: "4 – Musikstil?",
                  description: "Von Afrobeat und Rock bis K-Pop",
                  image: "/How-it-works-4.jpg",
                },
              ].map((step, index) => (
                <AnimatedElement key={index} delay={index * 0.1}>
                  <div className="space-y-2 mb-6 md:mb-0">
                    <div className="w-full aspect-square md:aspect-[4/5] bg-tralala-purple rounded-xl md:rounded-[24px] overflow-hidden transition-all duration-300 ease-[cubic-bezier(0.4, 0, 0.2, 1)]">
                      <img
                        src={step.image}
                        alt=""
                        className="w-full h-full object-cover"
                      />
                    </div>
                    <h4 className="text-tralala-red font-semibold text-base md:text-[1.5rem] leading-5 md:leading-[2.025rem] text-left">
                      {step.title}
                    </h4>
                    <p className="text-tralala-purple text-base md:text-[1.5rem] font-normal leading-5 md:leading-[2.025rem] text-left">
                      {step.description}
                    </p>
                  </div>
                </AnimatedElement>
              ))}
            </div>
          </div>
        </AnimatedSection>
      </div>

      {/* New is Better Section */}
      <AnimatedSection className="bg-tralala-purple px-4 py-20 md:py-40 text-tralala-bg">
        <div className="max-w-2xl mx-auto">
          <div className="flex flex-col items-center">
            <AnimatedElement className="mb-8 w-full max-w-[280px] md:max-w-none">
              <div className="relative w-full pb-[66.67%] transition-all duration-300">
                <img
                  src="/New-is-better-image.jpg"
                  alt="Collage of greeting cards and handwritten notes"
                  className="absolute inset-0 w-full h-full object-cover rounded-xl border border-[#FFF3DD] transform rotate-3"
                />
              </div>
            </AnimatedElement>
            <AnimatedElement className="text-center">
              <h2 className="text-3xl md:text-[3rem] font-medium mb-2 text-[#FFF3DD] leading-[105%]">
                Karten verschenken{" "}
                <span className="whitespace-nowrap">war gestern.</span>
              </h2>
              <p className="text-lg md:text-[1.5rem] mb-6 text-[#FFF3DD] leading-[135%]">
                Glückwunschkarten sind meist schneller vergessen als du
                schreiben kannst:{" "}
                <span className="italic whitespace-nowrap">
                  "Alles Liebe, Alles Gute"
                </span>
              </p>
            </AnimatedElement>
          </div>
        </div>
      </AnimatedSection>

      {/* Occasions Section */}
      <div id="anlaesse">
        <AnimatedSection
          className="px-4 py-[7.5rem] md:py-[15rem] bg-tralala-bg overflow-hidden"
        >
          <div className="max-w-[calc(100vw-3rem)] md:max-w-[calc(100vw-16rem)] mx-auto">
            <AnimatedElement>
              <h2 className="text-[2rem] md:text-[3rem] font-medium mb-10 md:mb-14 leading-[2.1rem] md:leading-[3.15rem] tracking-[-0.01em] text-tralala-purple text-center">
                Für jeden Anlass der richtige Song
              </h2>
            </AnimatedElement>
            <div className="flex flex-wrap justify-center items-center gap-3 md:gap-6">
              {occasions.map((occasion, index) => (
                <AnimatedElement key={index} delay={index * 0.05}>
                  <Link
                    to={occasion.path}
                    className="inline-flex p-4 md:p-8 flex-col items-center justify-center rounded-full transition-all hover:scale-110 md:hover:scale-110 focus:outline-none"
                    style={{
                      backgroundColor: occasion.color,
                      transform: `rotate(${occasion.rotation}deg)`,
                      fontSize: "clamp(1.5rem, 4vw, 2.25rem)",
                      fontWeight: 600,
                      letterSpacing: "-0.01em",
                      color: occasion.textColor,
                      textDecoration: "none",
                    }}
                  >
                    <span className="leading-normal text-center">
                      {occasion.name}
                    </span>
                  </Link>
                </AnimatedElement>
              ))}
            </div>
          </div>
        </AnimatedSection>
      </div>

      {/* FAQ Section */}
      <div id="faq">
        <AnimatedSection className="px-4 py-20 md:py-32 bg-tralala-bg pt-20 pb-20 md:pt-32 md:pb-32">
          <div className="max-w-[calc(100vw-3rem)] md:max-w-[calc(100vw-16rem)] lg:max-w-[60%] mx-auto">
            <AnimatedElement>
              <h2 className="text-[2rem] md:text-[3rem] font-medium mb-10 md:mb-14 leading-[2.1rem] md:leading-[3.15rem] tracking-[-0.01em] text-tralala-purple">
                Häufig gestellte Fragen
              </h2>
            </AnimatedElement>
            <div className="w-full">
              {faqItems.map((item, index) => (
                <AnimatedElement key={index} delay={index * 0.1}>
                  <FAQ
                    question={item.question}
                    answer={item.answer}
                    isOpen={faqStates[index]}
                    toggleFAQ={() => toggleFAQ(index)}
                  />
                </AnimatedElement>
              ))}
            </div>
          </div>
        </AnimatedSection>
      </div>

      {/* Footer */}
      <Footer />

      {/* Animated floating form */}
      <AnimatePresence>
        {showFloatingForm && renderEmailForm(true)}
      </AnimatePresence>
    </div>
  );
};

export default LandingPage;
