import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { LogOut, Music, PlusCircle, Info, MoreVertical } from "lucide-react";
import { signOut } from "firebase/auth";
import { auth } from "../firebase";
import { motion, AnimatePresence } from "framer-motion";
import Logo from "../assets/Logo";

const Header = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const isLandingPage = [
    '/', 
    '/wedding',
    '/christmas'
    // Add other landing page paths here as needed
  ].includes(location.pathname);

  if (isLandingPage) {
    return null;
  }

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate("/");
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const menuItems = [
    { name: "Song erstellen", icon: PlusCircle, href: "/generator" },
    { name: "Meine Songs", icon: Music, href: "/my-songs" },
    { name: "Hilfe", icon: Info, href: "/hilfe" },
    { name: "Abmelden", icon: LogOut, onClick: handleLogout },
  ];

  return (
    <header className="fixed top-0 left-0 right-0 flex justify-between items-center py-4 px-4 h-16 bg-transparent witp-menu z-[200]">
      <Link to="/" className="z-50">
        <Logo />
      </Link>
      {(location.pathname === "/generator" ||
        location.pathname === "/my-songs" ||
        location.pathname === "/hilfe") && (
        <div className="relative">
          <motion.button
            onClick={toggleMenu}
            className="flex items-center justify-center focus:outline-none z-50 relative"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            transition={{ duration: 0.2, ease: [0.4, 0, 0.2, 1] }}
            aria-label="Toggle menu"
          >
            <span className="mr-2 text-lg font-medium text-[#4B002F]">
              Menü
            </span>
            <motion.div
              animate={{ rotate: isMenuOpen ? 90 : 0 }}
              transition={{ duration: 0.2, ease: [0.4, 0, 0.2, 1] }}
            >
              <MoreVertical className="w-6 h-6 text-[#4B002F]" />
            </motion.div>
          </motion.button>

          <AnimatePresence>
            {isMenuOpen && (
              <>
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.3, ease: [0.4, 0, 0.2, 1] }}
                  className="fixed inset-0 bg-[#EBD2FE] witp-menu-background md:hidden"
                  style={{
                    animation:
                      isMenuOpen && window.innerWidth <= 768
                        ? "fillBackground 0.5s ease-out forwards"
                        : "none",
                  }}
                />
                <motion.nav
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.2, ease: [0.4, 0, 0.2, 1] }}
                  className="absolute top-16 right-4 space-y-2 w-64"
                >
                  {menuItems.map((item, index) => (
                    <motion.div
                      key={item.name}
                      initial={{ opacity: 0, x: 50 }}
                      animate={{ opacity: 1, x: 0 }}
                      transition={{
                        delay: index * 0.05,
                        duration: 0.2,
                        ease: [0.4, 0, 0.2, 1],
                      }}
                      style={{ marginLeft: `${index * 0.5}rem` }}
                      className="text-right"
                    >
                      <motion.a
                        href={item.href}
                        onClick={
                          item.onClick
                            ? (e) => {
                                e.preventDefault();
                                item.onClick();
                              }
                            : undefined
                        }
                        className="inline-flex items-center py-3 px-5 text-lg font-medium text-[#4B002F] bg-[#E1BCFE] rounded-full hover:bg-[#D5A6FF] transition-colors duration-200"
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                      >
                        <item.icon className="w-5 h-5 mr-2" />
                        {item.name}
                      </motion.a>
                    </motion.div>
                  ))}
                </motion.nav>
              </>
            )}
          </AnimatePresence>
        </div>
      )}
    </header>
  );
};

export default Header;
