/**
 * Scrolls to a specified section smoothly with an offset for the header.
 * @param {string} sectionId - The ID of the section to scroll to.
 * @param {number} offset - Optional offset in pixels (default: 100)
 */
export const scrollToSection = (sectionId, offset = 100) => {
    const section = document.getElementById(sectionId);
    if (section) {
      const elementPosition = section.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - offset;
      
      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth"
      });
    }
  };
  
  /**
   * Extracts the hash from a URL.
   * @param {string} url - The URL to extract the hash from.
   * @returns {string} The extracted hash without the '#' symbol, or an empty string if no hash is present.
   */
  export const getHashFromUrl = (url) => {
    const hashIndex = url.indexOf('#');
    return hashIndex !== -1 ? url.substring(hashIndex + 1) : '';
  };