import React from "react";

const ImprintPage = () => {
  return (
    <div className="container mx-auto px-4 py-24 max-w-3xl">
      <h1 className="text-3xl font-bold mb-8 text-tralala-purple">Impressum</h1>

      <div className="space-y-6 text-gray-700">
        <section className="space-y-2">
          <p>
            walk in the park UG (haftungsbeschr&auml;nkt),
            <br />
            Theaterplatz 2, 23909 Ratzeburg,
            <br />
            hi@walkinthepark.life
          </p>

          <p>Sitz der Gesellschaft: Hamburg</p>
          <p>Partnerschaftsregister: Amtsgericht Hamburg, HRB 25467 HL</p>
        </section>

        <section className="space-y-2">
          <h2 className="text-xl font-semibold text-tralala-purple">
            Geschäftsführer
          </h2>
          <p>Felix Gebauer-Lüdke, Maximilian Wilke</p>
        </section>

        <section className="space-y-2">
          <h2 className="text-xl font-semibold text-tralala-purple">
            Umsatzsteueridentifikationsnummer
          </h2>
          <p>TBD</p>
        </section>

        <section className="space-y-2">
          <h2 className="text-xl font-semibold text-tralala-purple">
            Verantwortlich für den Inhalt nach § 18 Abs. 2 MStV
          </h2>
          <p>Felix Gebauer-Lüdke, Maximilian Wilke</p>
        </section>

        <section className="space-y-2">
          <h2 className="text-xl font-semibold text-tralala-purple">
            Online-Streitbeilegung
          </h2>
          <p>
            Plattform der EU zur außergerichtlichen Online-Streitbeilegung:{" "}
            <a
              href="https://ec.europa.eu/consumers/odr/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-tralala-red hover:underline"
            >
              https://ec.europa.eu/consumers/odr/
            </a>
          </p>

          <p>
            Wir sind grundsätzlich nicht bereit oder verpflichtet, an
            Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle
            teilzunehmen.
          </p>
        </section>
      </div>
    </div>
  );
};

export default ImprintPage;
