import React from 'react';
import { motion } from 'framer-motion';
import { sendSignInLinkToEmail } from 'firebase/auth';
import { auth } from '../firebase';

const AUTH_EMAIL_KEY = 'auth_email_for_sign_in';

const EmailSignInForm = ({ 
  isFloating = false, 
  email, 
  setEmail, 
  status, 
  setStatus, 
  authError 
}) => {
  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus('sending');
  
    const actionCodeSettings = {
      url: `${window.location.origin}/generator?email=${encodeURIComponent(email)}`,
      handleCodeInApp: true,
    };
  
    try {
      await sendSignInLinkToEmail(auth, email, actionCodeSettings);
      sessionStorage.setItem(AUTH_EMAIL_KEY, email);
      localStorage.setItem(AUTH_EMAIL_KEY, email);
      setStatus('sent');
      setEmail('');
    } catch (error) {
      console.error('Error sending sign-in link', error);
      setStatus('error');
    }
  };

  return (
    <motion.div
      className={`transition-all duration-300 ease-in-out ${
        isFloating
          ? "fixed bottom-0 left-0 right-0 z-50"
          : "w-full max-w-lg mx-auto"
      }`}
      initial={isFloating ? { y: 100, opacity: 0 } : false}
      animate={isFloating ? { y: 0, opacity: 1 } : false}
      exit={isFloating ? { y: 100, opacity: 0 } : false}
      transition={{ duration: 0.3 }}
      layout="position"
    >
      <div className="max-w-4xl mx-auto px-4 py-4">
        <form
          onSubmit={handleSubmit}
          className={`${
            isFloating
              ? "flex flex-col sm:flex-row items-center space-y-4 sm:space-y-0 sm:space-x-4"
              : "flex flex-col items-center space-y-4"
          }`}
        >
          {status === "sent" ? (
            <div className={`h-16 px-6 py-4 rounded-full bg-[#FFF3DD] text-[#4B002F] text-xl sm:text-2xl text-center outline-none ring-2 ring-tralala-red flex items-center justify-center w-full`}>
              Öffne deine E-Mails und klick' auf den Link!
            </div>
          ) : (
            <>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Deine Email..."
                autoComplete="email"
                className={`h-16 px-6 py-4 rounded-full bg-[#FFF3DD] text-[#4B002F] text-xl sm:text-2xl text-center outline-none ring-2 ring-tralala-red focus:ring-4 transition-shadow duration-300 ${
                  isFloating ? "w-full sm:w-3/5" : "w-full"
                }`}
                disabled={status === "sending"}
              />
              <button
                type="submit"
                disabled={status === "sending" || !email}
                className={`h-16 bg-tralala-red text-[#FFF3DD] px-4 sm:px-6 py-2 sm:py-4 rounded-full font-semibold flex flex-col items-center justify-center ${
                  status === "sending" || !email
                    ? "cursor-not-allowed"
                    : "hover:bg-tralala-red transition-colors duration-300"
                } ${isFloating ? "w-full sm:w-2/5" : "w-full"}`}
              >
                <span className="text-lg sm:text-xl leading-tight whitespace-normal sm:whitespace-nowrap">
                  Jetzt Song erstellen
                </span>
                <span className="text-xs sm:text-sm whitespace-nowrap">
                  Für nur 2,90€
                </span>
              </button>
            </>
          )}
        </form>
      </div>
      {authError && (
        <div className="text-red-500 text-center mb-4">{authError}</div>
      )}
      {status === "error" && (
        <p className="text-red-500 text-center mt-2">
          Fehler bei der E-mail Eingabe. Bitte versuch's nochmal.
        </p>
      )}
    </motion.div>
  );
};

export default EmailSignInForm; 