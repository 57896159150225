import React, { lazy, Suspense, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { db, functions, auth } from "./firebase";
import { AuthProvider, useAuth } from './auth/AuthContext';
import { isSignInWithEmailLink } from 'firebase/auth';
import SunoSongGenerator from "./components/SunoSongGenerator";
import LandingPage from "./components/LandingPage";
import Header from "./components/Header";
import MySongsPage from "./components/MySongsPage";
import HelpPage from './components/HelpPage';
import ImprintPage from './components/ImprintPage';
import LegalPage from './components/LegalPage';
import { scrollToSection } from './utils/scrollUtils';

const WeddingLandingPage = lazy(() =>
  import("./components/OccasionLandingPages/WeddingLandingPage")
);

const ChristmasLandingPage = lazy(() =>
  import("./components/OccasionLandingPages/ChristmasLandingPage")
);

const LoadingSpinner = () => (
  <div className="min-h-screen flex items-center justify-center bg-tralala-bg">
    <div className="text-center space-y-4">
      <div className="animate-spin rounded-full h-12 w-12 border-4 border-tralala-red border-t-transparent mx-auto"></div>
      <p className="text-tralala-purple text-lg">Einen Moment bitte...</p>
    </div>
  </div>
);

const GeneratorPage = () => {
  const { user, loading, authError } = useAuth();
  const isEmailLink = isSignInWithEmailLink(auth, window.location.href);

  // Show loading state while processing email link or general auth loading
  if (loading || (!user && isEmailLink)) {
    return <LoadingSpinner />;
  }

  // Show error state if auth failed
  if (authError) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-tralala-bg px-4">
        <div className="max-w-md w-full text-center space-y-4">
          <div className="rounded-lg bg-red-50 p-4">
            <p className="text-red-700">{authError}</p>
          </div>
          <a 
            href="/"
            className="inline-block px-6 py-3 rounded-full bg-tralala-red text-[#FFF3DD] hover:bg-opacity-90 transition-colors"
          >
            Zurück zur Startseite
          </a>
        </div>
      </div>
    );
  }

  // Redirect to landing if not authenticated and not processing email link
  if (!user && !isEmailLink) {
    return <Navigate to="/" replace />;
  }

  // Show generator when authenticated
  return <SunoSongGenerator functions={functions} db={db} />;
};

const ProtectedRoute = ({ children }) => {
  const { user, loading } = useAuth();
  
  if (loading) return <LoadingSpinner />;
  if (!user) return <Navigate to="/" replace />;
  return children;
};

function App() {
  useEffect(() => {
    const handleHashChange = () => {
      const { hash } = window.location;
      if (hash) {
        const sectionId = hash.replace('#', '');
        scrollToSection(sectionId);
      }
    };

    window.addEventListener("hashchange", handleHashChange, false);
    
    // Call the function on initial load if there's a hash
    if (window.location.hash) {
      handleHashChange();
    }

    return () => {
      window.removeEventListener("hashchange", handleHashChange, false);
    };
  }, []);

  return (
    <AuthProvider>
      <Router>
        <div className="min-h-screen flex flex-col bg-background font-sans">
          <Header />
          <Suspense fallback={<LoadingSpinner />}>
            <Routes>
              <Route
                path="/"
                element={<LandingPage />}
              />
              <Route
                path="/generator"
                element={<GeneratorPage />}
              />
              <Route 
                path="/wedding" 
                element={<WeddingLandingPage />} 
              />
              <Route 
                path="/christmas" 
                element={<ChristmasLandingPage />} 
              />
              <Route
                path="/my-songs"
                element={
                  <ProtectedRoute>
                    <MySongsPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/hilfe"
                element={<HelpPage />}
              />
              <Route
                path="/impressum"
                element={<ImprintPage />}
              />
              <Route
                path="/legal"
                element={<LegalPage />}
              />
            </Routes>
          </Suspense>
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;