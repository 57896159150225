import React, { useEffect } from "react";
import { scrollToSection } from '../utils/scrollUtils';

const LegalPage = () => {
  useEffect(() => {
    // Get the hash from the URL and scroll to the section
    const hash = window.location.hash;
    if (hash) {
      const sectionId = hash.replace('#', '');
      // Add a small delay to ensure the page content is loaded
      setTimeout(() => {
        scrollToSection(sectionId);
      }, 100);
    }
  }, []); // Empty dependency array means this runs once when component mounts

  return (
    <div className="container mx-auto px-4 py-24 max-w-4xl">
      <h1 className="text-3xl font-bold mb-12 text-tralala-purple">
        Rechtliches
      </h1>

      {/* AGB Section */}
      <div id="agb" className="mb-16 space-y-8">
        <h2 className="text-2xl font-bold text-tralala-purple">
          Allgemeine Geschäftsbedingungen („AGB")
        </h2>
        <p>&sect;&nbsp;1 Geltungsbereich, Begriffsbestimmungen</p>
        <p>Diese AGB gelten f&uuml;r Vertr&auml;ge zwischen der</p>
        <p>
          walk in the park UG (haftungsbeschr&auml;nkt),
          <br />
          Theaterplatz 2, 23909 Ratzeburg,
          <br />
          hi@walkinthepark.life
        </p>
        <p>Umsatzsteuer-ID-Nr.: TBD</p>
        <p>
          nachfolgend &ldquo;Anbieter&rdquo; genannt, und dem Auftraggeber,
          nachfolgend &ldquo;Kunde&rdquo; genannt, &uuml;ber den Kauf digitaler
          Inhalte &uuml;ber die Website www.tralala.me (&bdquo;Website&ldquo;).
        </p>
        <p>&sect;&nbsp;2 Vertragsschluss</p>
        <p>
          Der Vertrag zwischen dem Anbieter und dem Kunden kommt dadurch
          zustande, dass der Kunde den Erstellvorgang auf der Website
          vollst&auml;ndig durchlaufen hat und auf den Button
          &bdquo;PayPal&ldquo; klickt. Bis dahin k&ouml;nnen Sie Ihre Angaben
          jederzeit durch erneute Eingabe korrigieren.
        </p>
        <p>&sect;&nbsp;3 Produkt</p>
        <p>
          <strong>
            Bei Abschluss des Vertrags erh&auml;lt der Kunde einen aufgrund
            seiner Angaben im Bestellvorgang von einer k&uuml;nstlichen
            Intelligenz erstellten Song. Dieser steht ihm nach wenigen Minuten
            zum Download &uuml;ber die Website bereit.
          </strong>
        </p>
        <p>&sect;&nbsp;4 Preise</p>
        <ul className="list-disc pl-5">
          <li>
            Alle Preise, die auf der Website des Anbieters angegeben sind,
            verstehen sich einschlie&szlig;lich der jeweils g&uuml;ltigen
            gesetzlichen Umsatzsteuer.
          </li>
          <li>
            Es gelten die Preise, wie sie zum Zeitpunkt der Bestellung auf der
            Website angegeben sind. Die Zahlung erfolgt per Vorkasse via PayPal.
          </li>
        </ul>
        <p>&sect;&nbsp;5 Haftungsausschluss</p>
        <ul className="list-disc pl-5">
          <li>
            Der Anbieter haftet f&uuml;r leichte Fahrl&auml;ssigkeit nur, soweit
            eine Pflicht verletzt wird, deren Einhaltung f&uuml;r die Erreichung
            des Vertragszweckes von besonderer Bedeutung ist (Kardinalpflicht)
            sowie in F&auml;llen der Verletzung des Lebens, des K&ouml;rpers
            oder der Gesundheit.
          </li>
          <li>
            Bei Verletzung einer Kardinalpflicht ist die Haftung auf solche
            Sch&auml;den beschr&auml;nkt, mit deren Entstehung im Rahmen der
            vorliegenden Vereinbarung typischerweise gerechnet werden muss,
            soweit nicht Vorsatz oder grobe Fahrl&auml;ssigkeit gegeben sind
            oder wegen der Verletzung des Lebens, des K&ouml;rpers oder der
            Gesundheit zwingend gehaftet wird.Die Haftung nach dem
            Produkthaftungsgesetz bleibt unber&uuml;hrt.
          </li>
          <li>
            Der Anbieter haftet nicht f&uuml;r vom Kunden beherrschbare
            Sch&auml;den bzw. Sch&auml;den, die der Kunde durch ihm zumutbare
            Ma&szlig;nahmen h&auml;tte verhindern k&ouml;nnen.
          </li>
          <li>
            Eine &Auml;nderung der Beweislast zum Nachteil des Kunden ist mit
            den vorstehenden Regelungen nicht verbunden.
          </li>
        </ul>
        <p>&sect;&nbsp;6 Widerrufsbelehrung</p>
        <p>
          Verbraucher haben bei Abschluss eines Fernabsatzgesch&auml;fts ein
          gesetzliches Widerrufsrecht, &uuml;ber das der Anbieter nach
          Ma&szlig;gabe des gesetzlichen Musters nachfolgend informiert:
        </p>
        <table cellSpacing="0" cellPadding="0">
          <tbody>
            <tr>
              <td>
                <p>
                  <strong>Widerrufsbelehrung</strong>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <strong>Widerrufsrecht</strong>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  Sie haben das Recht, binnen vierzehn Tagen ohne Angabe von
                  Gr&uuml;nden diesen Vertrag zu widerrufen.
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  Die Widerrufsfrist betr&auml;gt vierzehn Tage ab dem
                  Tag&nbsp;an dem Sie oder ein von Ihnen benannter Dritter, der
                  nicht der Bef&ouml;rderer ist, die Waren in Besitz genommen
                  haben bzw. hat.
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  Um Ihr Widerrufsrecht auszu&uuml;ben, m&uuml;ssen Sie uns
                  (Firma, Adresse, Telefonnummer, E-Mail) mittels einer
                  eindeutigen Erkl&auml;rung (z. B. ein mit der Post versandter
                  Brief oder eine E-Mail) &uuml;ber Ihren Entschluss, diesen
                  Vertrag zu widerrufen, informieren.
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die
                  Mitteilung &uuml;ber die Aus&uuml;bung des Widerrufsrechts vor
                  Ablauf der Widerrufsfrist absenden.
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <strong>Folgen des Widerrufs</strong>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle
                  Zahlungen, die wir von Ihnen erhalten haben,
                  einschlie&szlig;lich der Lieferkosten (mit Ausnahme der
                  zus&auml;tzlichen Kosten, die sich daraus ergeben, dass Sie
                  eine andere Art der Lieferung als die von uns angebotene,
                  g&uuml;nstigste Standardlieferung gew&auml;hlt haben),
                  unverz&uuml;glich und sp&auml;testens binnen vierzehn Tagen ab
                  dem Tag zur&uuml;ckzuzahlen, an dem die Mitteilung &uuml;ber
                  Ihren Widerruf dieses Vertrags bei uns eingegangen ist.
                  F&uuml;r diese R&uuml;ckzahlung verwenden wir dasselbe
                  Zahlungsmittel, das Sie bei der urspr&uuml;nglichen
                  Transaktion eingesetzt haben, es sei denn, mit Ihnen wurde
                  ausdr&uuml;cklich etwas anderes vereinbart; in keinem Fall
                  werden Ihnen wegen dieser R&uuml;ckzahlung Entgelte berechnet.
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        <p>&sect;&nbsp;7 Schlussbestimmungen</p>
        <p>
          (1) Auf Vertr&auml;ge zwischen dem Anbieter und den Kunden findet das
          Recht der Bundesrepublik Deutschland unter Ausschluss des
          UN-Kaufrechts Anwendung. Die gesetzlichen Vorschriften zur
          Beschr&auml;nkung der Rechtswahl und zur Anwendbarkeit zwingender
          Vorschriften insbes. des Staates, in dem der Kunde als Verbraucher
          seinen gew&ouml;hnlichen Aufenthalt hat, bleiben unber&uuml;hrt.
        </p>
        <p>
          (2) Sofern es sich beim Kunden um einen Kaufmann, eine juristische
          Person des &ouml;ffentlichen Rechts oder um ein
          &ouml;ffentlich-rechtliches Sonderverm&ouml;gen handelt, ist
          Gerichtsstand f&uuml;r alle Streitigkeiten aus
          Vertragsverh&auml;ltnissen zwischen dem Kunden und dem Anbieter der
          Sitz des Anbieters.
        </p>
        <p>
          (3) Der Vertrag bleibt auch bei rechtlicher Unwirksamkeit einzelner
          Punkte in seinen &uuml;brigen Teilen verbindlich. Anstelle der
          unwirksamen Punkte treten, soweit vorhanden, die gesetzlichen
          Vorschriften. Soweit dies f&uuml;r eine Vertragspartei eine
          unzumutbare H&auml;rte darstellen w&uuml;rde, wird der Vertrag jedoch
          im Ganzen unwirksam.
        </p>
      </div>

      {/* Datenschutz Section */}
      <div id="datenschutz" className="space-y-8">
        <h2 className="text-2xl font-bold text-tralala-purple">
          Datenschutzhinweis gemäß Art. 13 DSGVO
        </h2>
        <p>
          1. Namen und Kontaktdaten des Verantwortlichen sowie seiner Vertreter:
        </p>
        <p>[Firma, Adresse, E-Mail]</p>
        <p>
          Vertretungsberechtigte: Felix Gebauer-L&uuml;dke, Maximilian Wilke
        </p>
        <p>
          2. Art der personenbezogenen Daten, Zwecke der Verarbeitung,
          Rechtsgrundlage, Dauer der Speicherung:
        </p>
        <p>a) Bei Aufruf der Website:</p>
        <p>
          Beim Aufrufen unserer Website tralala.me werden durch den auf Ihrem
          Endger&auml;t zum Einsatz kommenden Browser automatisch Informationen
          an den Server unserer Website gesendet. Diese Informationen werden
          tempor&auml;r in einem sog. Logfile gespeichert. Folgende
          Informationen werden dabei ohne Ihr Zutun erfasst und bis zur
          automatisierten L&ouml;schung gespeichert:
        </p>
        <ul className="list-disc pl-5">
          <li>IP-Adresse des anfragenden Rechners</li>
          <li>Datum und Uhrzeit des Zugriffs</li>
          <li>Name und URL der abgerufenen Datei</li>
          <li>Website, von der aus der Zugriff erfolgt (Referrer-URL)</li>
          <li>verwendeter Browser und ggf. das Betriebssystem Ihres Rechner</li>
          <li>sowie der Name Ihres Access-Providers.</li>
        </ul>
        <p>
          Die genannten Daten werden durch uns zu folgenden Zwecken verarbeitet:
        </p>
        <ul className="list-disc pl-5">
          <li>
            Gew&auml;hrleistung eines reibungslosen Verbindungsaufbaus der
            Website
          </li>
          <li>
            Gew&auml;hrleistung einer komfortablen Nutzung unserer Website
          </li>
          <li>Auswertung der Systemsicherheit und -stabilit&auml;t sowie</li>
          <li>zu weiteren administrativen Zwecken.</li>
        </ul>
        <p>
          Rechtsgrundlage f&uuml;r die Verarbeitung ist Art. 6 Abs. 1 S. 1 lit.
          f DSGVO. Das damit verfolgte berechtigte Interesse ist die
          Au&szlig;endarstellung unseres Unternehmens im Internet.
        </p>
        <p>b) Erstellen eines Songs:</p>
        <p>
          Um einen Song erstellen zu k&ouml;nnen, ben&ouml;tigen wir die Angabe
          einer g&uuml;ltigen E-Mail-Adresse. Diese ben&ouml;tigen wir um den
          Song einem Nutzer zuordnen zu k&ouml;nnen. Damit der Song klingt, wie
          von Ihnen gew&uuml;nscht, stellen wir Ihnen vier Fragen. Die Antworten
          auf diese Fragen verarbeiten wir ausschlie&szlig;lich zur Erstellung
          des Songs. Sie werden danach gel&ouml;scht. Rechtsgrundlage f&uuml;r
          die Verarbeitung ist Art. 6 Abs. 1 S. 1 lit. b DSGVO.
        </p>
        <p>
          Die Bezahlung erfolgt &uuml;ber PayPal. Hierzu werden Sie &uuml;ber
          einen Link von unserer Website auf die Seite von PayPal
          weitergeleitet. Wir erhalten keinerlei Kenntnis &uuml;ber Ihre
          Bezahldaten, sondern lediglich die Best&auml;tigung, dass Sie gezahlt
          haben. Weitere Informationen zum Bezahlvorgang entnehmen Sie bitte der
          Datenschutzerkl&auml;rung von PayPal.
        </p>
        <p>c) Speicherdauer</p>
        <p>
          Ihre bei uns gespeicherten Daten werden nur so lange gespeichert, wie
          es zur Erf&uuml;llung des entsprechenden Zwecks erforderlich ist. Nach
          Ablauf der Speicherfristen werden Ihre Daten gel&ouml;scht, sofern
          eine dar&uuml;berhinausgehende Speicherung nicht aus
          Rechtsgr&uuml;nden erforderlich ist. Eine dar&uuml;berhinausgehende
          Speicherung kann insbesondere notwendig sein, soweit dies zur
          Geltendmachung, Aus&uuml;bung oder Verteidigung von
          Rechtsanspr&uuml;chen f&uuml;r die Dauer eines Rechtsstreits
          erforderlich ist. In diesem Fall werden die Daten jedoch f&uuml;r eine
          weitere Verwendung gesperrt.
        </p>
        <p>3. Weitergabe von Daten</p>
        <p>a) Allgemeines:</p>
        <p>
          Innerhalb unseres Unternehmens erhalten nur die mit dem Projekt
          betrauten Mitarbeitenden Zugriff. Diese sind zur Verschwiegenheit
          verpflichtet.
        </p>
        <p>
          An Dritte geben wir Ihre personenbezogenen Daten nur weiter, wenn:
        </p>
        <ul className="list-disc pl-5">
          <li>
            Sie Ihre nach Art. 6 Abs. 1 S. 1 lit. a DSGVO ausdr&uuml;ckliche
            Einwilligung dazu erteilt haben,
          </li>
          <li>
            die Weitergabe nach Art. 6 Abs. 1 S. 1 lit. f DSGVO zur
            Geltendmachung, Aus&uuml;bung oder Verteidigung von
            Rechtsanspr&uuml;chen erforderlich ist und kein Grund zur Annahme
            besteht, dass Sie ein &uuml;berwiegendes schutzw&uuml;rdiges
            Interesse an der Nichtweitergabe Ihrer Daten haben,
          </li>
          <li>
            f&uuml;r den Fall, dass f&uuml;r die Weitergabe nach Art. 6 Abs. 1
            S. 1 lit. c DSGVO eine gesetzliche Verpflichtung besteht, sowie
          </li>
          <li>
            dies gesetzlich zul&auml;ssig und nach Art. 6 Abs. 1 S. 1 lit. b
            DSGVO f&uuml;r die Abwicklung von Vertragsverh&auml;ltnissen mit
            Ihnen erforderlich ist.
          </li>
        </ul>
        <p>b) Auftragsverarbeiter:</p>
        <p>
          Soweit wir bei der Verarbeitung Auftragsverarbeiter einsetzen, erfolgt
          die Verarbeitung auf Grundlage eines Auftragsverarbeitungsvertrags,
          der den Auftragsverarbeiter gem&auml;&szlig; Art. 28 DSGVO in Bezug
          auf den Verantwortlichen bindet und in dem Gegenstand und Dauer der
          Verarbeitung, Art und Zweck der Verarbeitung, die Art der
          personenbezogenen Daten, die Kategorien betroffener Personen und die
          Pflichten und Rechte des Verantwortlichen festgelegt sind.
          Auftragsverarbeiter nutzen die Daten nicht f&uuml;r eigene Zwecke,
          sondern f&uuml;hren die Datenverarbeitung ausschlie&szlig;lich
          f&uuml;r den Verantwortlichen aus.
        </p>
        <p>
          F&uuml;r die Umsetzung des Angebots setzen wir Google Firebase als
          Auftragsverarbeiter ein. Google Firebase ist ein Produkt der Google
          Cloud EMEA Limited, 70 Sir John Rogerson&rsquo;s Quay, Dublin 2,
          Ireland (&ldquo;Google&rdquo;). Das hei&szlig;t, Google ist
          vertraglich verpflichtet, die Daten ausschlie&szlig;lich auf unsere
          Weisung hin zu verarbeiten. Weiter Informationen zur Verarbeitung
          personenbezogener Daten bei Google Firebase finden Sie hier:
          https://firebase.google.com/support/privacy?hl=de. Ferner nutzen wir
          Ressourcen der Vercel Inc, 650 California St, San Francisco, CA 94108,
          US (&ldquo;Vercel&rdquo;). Auch Vercel wird im Rahmen einer
          Auftragsverarbeitung f&uuml;r uns t&auml;tig. Weitere Informationen
          hierzu finden Sie hier: https://vercel.com/legal/dpa.
        </p>
        <p>4. Betroffenenrechte</p>
        <p>Sie haben das Recht</p>
        <ul className="list-disc pl-5">
          <li>
            gem&auml;&szlig; Art. 15 DSGVO Auskunft &uuml;ber Ihre von uns
            verarbeiteten personenbezogenen Daten zu verlangen. Insbesondere
            k&ouml;nnen Sie Auskunft &uuml;ber die Verarbeitungszwecke, die
            Kategorie der personenbezogenen Daten, die Kategorien von
            Empf&auml;ngern, gegen&uuml;ber denen Ihre Daten offengelegt wurden
            oder werden, die geplante Speicherdauer, das Bestehen eines Rechts
            auf Berichtigung, L&ouml;schung, Einschr&auml;nkung der Verarbeitung
            oder Widerspruch, das Bestehen eines Beschwerderechts, die Herkunft
            ihrer Daten, sofern diese nicht bei uns erhoben wurden, sowie
            &uuml;ber das Bestehen einer automatisierten Entscheidungsfindung
            einschlie&szlig;lich Profiling und ggf. aussagekr&auml;ftigen
            Informationen zu deren Einzelheiten verlangen
          </li>
          <li>
            gem&auml;&szlig; Art. 16 DSGVO unverz&uuml;glich die Berichtigung
            unrichtiger oder Vervollst&auml;ndigung Ihrer bei uns gespeicherten
            personenbezogenen Daten zu verlangen
          </li>
          <li>
            gem&auml;&szlig; Art. 17 DSGVO die L&ouml;schung Ihrer bei uns
            gespeicherten personenbezogenen Daten zu verlangen, soweit nicht die
            Verarbeitung zur Aus&uuml;bung des Rechts auf freie
            Meinungs&auml;u&szlig;erung und Information, zur Erf&uuml;llung
            einer rechtlichen Verpflichtung, aus Gr&uuml;nden des
            &ouml;ffentlichen Interesses oder zur Geltendmachung, Aus&uuml;bung
            oder Verteidigung von Rechtsanspr&uuml;chen erforderlich ist
          </li>
          <li>
            gem&auml;&szlig; Art. 18 DSGVO die Einschr&auml;nkung der
            Verarbeitung Ihrer personenbezogenen Daten zu verlangen, soweit die
            Richtigkeit der Daten von Ihnen bestritten wird, die Verarbeitung
            unrechtm&auml;&szlig;ig ist, Sie aber deren L&ouml;schung ablehnen
            und wir die Daten nicht mehr ben&ouml;tigen, Sie jedoch diese zur
            Geltendmachung, Aus&uuml;bung oder Verteidigung von
            Rechtsanspr&uuml;chen ben&ouml;tigen oder Sie gem&auml;&szlig; Art.
            21 DSGVO Widerspruch gegen die Verarbeitung eingelegt haben
          </li>
          <li>
            gem&auml;&szlig; Art. 20 DSGVO Ihre personenbezogenen Daten, die Sie
            uns bereitgestellt haben, in einem strukturierten, g&auml;ngigen und
            maschinenlesebaren Format zu erhalten oder die &Uuml;bermittlung an
            einen anderen Verantwortlichen zu verlangen
          </li>
          <li>
            gem&auml;&szlig; Art. 7 Abs. 3 DSGVO Ihre einmal erteilte
            Einwilligung jederzeit gegen&uuml;ber uns zu widerrufen. Dies hat
            zur Folge, dass wir die Datenverarbeitung, die auf dieser
            Einwilligung beruhte, f&uuml;r die Zukunft nicht mehr
            fortf&uuml;hren d&uuml;rfen und
          </li>
          <li>
            gem&auml;&szlig; Art. 77 DSGVO sich bei einer Aufsichtsbeh&ouml;rde
            zu beschweren. In der Regel k&ouml;nnen Sie sich hierf&uuml;r an die
            Aufsichtsbeh&ouml;rde Ihres &uuml;blichen Aufenthaltsortes oder
            Arbeitsplatzes oder unseres Unternehmenssitzes wenden.
          </li>
        </ul>
        <p>
          <strong>5. Widerspruchsrecht</strong>
        </p>
        <p>
          <strong>
            Sofern Ihre personenbezogenen Daten auf Grundlage von berechtigten
            Interessen gem&auml;&szlig; Art. 6 Abs. 1 S. 1 lit. f DSGVO
            verarbeitet werden, haben Sie das Recht, gem&auml;&szlig; Art. 21
            DSGVO Widerspruch gegen die Verarbeitung Ihrer personenbezogenen
            Daten einzulegen, soweit daf&uuml;r Gr&uuml;nde vorliegen, die sich
            aus Ihrer besonderen Situation ergeben oder sich der Widerspruch
            gegen Direktwerbung richtet. Im letzteren Fall haben Sie ein
            generelles Widerspruchsrecht, das ohne Angabe einer besonderen
            Situation von uns umgesetzt wird.
          </strong>
        </p>
        <p>
          <strong>
            M&ouml;chten Sie von Ihrem Widerrufs- oder Widerspruchsrecht
            Gebrauch machen, gen&uuml;gt eine E-Mail an [&hellip;].
          </strong>
        </p>
        <p>6. Datensicherheit</p>
        <p>
          Wir verwenden das verbreitete SSL-Verfahren (Secure Socket Layer) in
          Verbindung mit der jeweils h&ouml;chsten Verschl&uuml;sselungsstufe,
          die von Ihrem Browser unterst&uuml;tzt wird. Ob eine einzelne Seite
          unseres Internetauftrittes verschl&uuml;sselt &uuml;bertragen wird,
          erkennen Sie an der geschlossenen Darstellung des Sch&uuml;ssel-
          beziehungsweise Schloss-Symbols in der unteren Statusleiste Ihres
          Browsers.
        </p>
        <p>
          Wir bedienen uns im &Uuml;brigen geeigneter technischer und
          organisatorischer Sicherheitsma&szlig;nahmen, um Ihre Daten gegen
          zuf&auml;llige oder vors&auml;tzliche Manipulationen, teilweisen oder
          vollst&auml;ndigen Verlust, Zerst&ouml;rung oder gegen den unbefugten
          Zugriff Dritter zu sch&uuml;tzen. Unsere Sicherheitsma&szlig;nahmen
          werden entsprechend der technologischen Entwicklung fortlaufend
          verbessert.
        </p>
        <p>7. Verpflichtung zur Bereitstellung der personenbezogenen Daten</p>
        <p>
          Sie sind zur Bereitstellung Ihrer personenbezogenen Daten nicht
          verpflichtet. Die Bereitstellung Ihrer personenbezogenen Daten ist
          jedoch, im beschriebenen Umfang, f&uuml;r die Nutzung unseres Dienstes
          erforderlich. Die Nichtbereitstellung dieser Daten h&auml;tte zur
          Folge, dass wir Ihnen keinen Song erstellen k&ouml;nnen.
        </p>
        <p>
          8. Aktualit&auml;t und &Auml;nderung dieser Datenschutzerkl&auml;rung
        </p>
        <p>
          Diese Datenschutzerkl&auml;rung ist aktuell g&uuml;ltig und hat den
          Stand November 2024. Durch die Weiterentwicklung unserer Website und
          Angebote dar&uuml;ber oder aufgrund ge&auml;nderter gesetzlicher
          beziehungsweise beh&ouml;rdlicher Vorgaben kann es notwendig werden,
          diese Datenschutzerkl&auml;rung zu &auml;ndern.
        </p>
      </div>
    </div>
  );
};

export default LegalPage;
